export default {
    head() {
        return {
            ...this.$nuxtI18nHead({ addSeoAttributes: true }),
            meta: [
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t('This is the localized description'),
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('This is the localized description'),
                },
                {
                    property: 'og:url',
                    content: this.$config.baseURL + this.$route.fullPath,
                },
            ],
        };
    },
};
