<template>
    <transition name="fade">
        <div
            v-if="!$cookies.get('the_first_load_is_done') && isLoading"
            class="w-screen h-dynamic-screen fixed top-0 flex items-center justify-center bg-white flex-col z-[55] gap-y-4"
        >
            <div class="relative">
                <div class="flex gap-x-[0.075rem] scale-100 lg:scale-[1.18]">
                    <div class="letter letter--k">
                        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.4437 23.7328H0V0.978027H19.1854L11.6396 11.1852L19.4437 23.7328Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--a">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6092 0L24.6714 23.7332H0.546875L12.6092 0Z" fill="#227AFF" />
                        </svg>
                    </div>
                    <div class="letter letter--r">
                        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.2817 23.7328H0.785156V0.978027H8.45617C12.5813 0.978027 18.8903 0.978027 18.8903 7.77236C18.8903 11.2869 17.1996 13.5569 13.458 14.5354L19.2739 23.7328H19.2817Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--t">
                        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.9329 8.96997H14.3049V23.7328H6.17986V8.96997H0.00390625V0.978027H19.9329V8.9778V8.96997Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--second-a">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.7556 0L24.8179 23.7332H0.693359L12.7556 0Z" fill="#FF3B20" />
                        </svg>
                    </div>
                    <div class="letter letter--p">
                        <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.05666 16.8445V23.7406H0.931641V0.978027H9.05666C11.7572 0.978027 19.0055 1.30679 19.0055 9.00911C19.0055 14.1127 15.7571 16.6488 9.05666 16.8445Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--o">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.861328 12.3204C0.861328 5.55742 5.83966 0.586914 12.6653 0.586914C19.4909 0.586914 24.3988 5.55742 24.3988 12.3204C24.3988 19.0835 19.4283 24.1244 12.6966 24.1244C5.96491 24.1244 0.861328 19.1539 0.861328 12.3204Z"
                                fill="#FFD600"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--l">
                        <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.4826 15.733V23.7328H0.162109V0.978027H8.28713V15.7408H16.4826V15.733Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                    <div class="letter letter--i">
                        <svg width="9" height="24" viewBox="0 0 9 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.308594 0.978027H8.4336V23.7328H0.308594V0.978027Z" fill="#001426" />
                        </svg>
                    </div>
                    <div class="letter letter--s">
                        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.431651 15.7335C4.72116 15.7335 6.21623 14.6298 8.62712 8.5791C10.7092 3.34246 13.535 0.970703 18.8969 0.970703H20.0006V8.93917H19.9693C17.4331 8.93917 16.5251 11.2483 15.1866 14.9194C12.9792 21.0014 8.90891 23.7333 2.53727 23.7333H0.423828V15.7335H0.431651Z"
                                fill="#001426"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LoadingScreen',
    data() {
        return {
            isLoading: true,
        };
    },
    watch: {
        '$store.state.globals.isPageLoading'(value) {
            if (!value) {
                setTimeout(() => {
                    this.isLoading = false;
                    this.$cookies.set('the_first_load_is_done', true, {
                        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                    });
                }, 3000);
            } else {
                this.isLoading = true;
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.$store.commit('globals/setIsPageLoading', false);
        }, 2000);
    },
};
</script>

<style scoped>
@keyframes flip {
    0% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
    }
    75% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}

/* lint-disable unit-no-unknown */
.h-dynamic-screen {
    height: 100dvh;
    @supports not (height: 100dvh) {
        height: 100vh;
    }
}

/* lint-enable unit-no-unknown */
.letter {
    transform: scaleX(0);
    animation: flip 2.25s infinite;
    &--k {
        animation-delay: 0.5s;
    }
    &--a {
        animation-delay: 0.75s;
    }
    &--r {
        animation-delay: 1s;
    }
    &--t {
        animation-delay: 1.25s;
    }
    &--second-a {
        animation-delay: 1.5s;
    }
    &--p {
        animation-delay: 1.75s;
    }
    &--o {
        animation-delay: 2s;
    }
    &--l {
        animation-delay: 2.25s;
    }
    &--i {
        animation-delay: 2.5s;
    }
    &--s {
        animation-delay: 2.75s;
    }
}
</style>
