<template>
    <BaseModal :close="close" :is-open="isOpen">
        <div
            class="bg-white rounded-t-xl lg:rounded-md w-screen md:w-[78vw] xl:w-[45vw] h-[65vh] lg:h-auto px-6 pt-9 pb-11 lg:p-16 lg:pb-24"
        >
            <div class="flex flex-col justify-end lg:justify-center text-center relative">
                <div
                    v-if="isForgottenPasswordRequestSent"
                    class="absolute top-0 left bg-white w-full h-full z-10 flex items-center justify-center flex-col p-5"
                >
                    <img
                        class="mb-4 w-16 h-auto"
                        :src="require('~/assets/images/icons/checkmark-icon--green.svg')"
                        alt="Checkmark icon"
                    />
                    <div class="w-full lg:w-9/12">
                        <span class="font-Inter-Bold text-[1.5rem] lg:text-2xl mb-3">
                            A kérelmet sikeresen elküldtük
                        </span>
                        <p>Az email cím megváltoztatásához tartozó instrukciókat az email-ben találja.</p>
                    </div>
                </div>
                <LoginForm />
            </div>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from '~/components/UI/BaseModal.vue';
import LoginForm from '@/components/Forms/LoginForm.vue';
export default {
    components: {
        LoginForm,
        BaseModal,
    },
    props: {
        close: {
            type: Function,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            isForgottenPasswordRequestSent: false,
            isErrorVisible: false,
            newPassword: null,
            registeredEmail: null,
            newPasswordAgain: null,
            registeredUserEmail: null,
            registeredUserPassword: null,
        };
    },
    methods: {
        sendForgottenPasswordRequest() {
            this.isForgottenPasswordRequestSent = true;
        },
    },
};
</script>
