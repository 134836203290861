<template>
    <div
        v-if="getRouteBaseName() === 'magazine-article' && $store.state.blog.doesProgressBarHaveTransition"
        class="absolute w-full h-1 bottom-0 left-0 bg-grey-100"
    >
        <div
            class="absolute bottom-0 left-0 h-1 bg-yellow-100 transition-all"
            :style="`width: ${blogArticleReadProgressPercentile}%`"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ReadProgressbar',
    computed: {
        blogArticleReadProgressPercentile() {
            const _ret = (this.$store.state.globals.scrollY / this.$store.state.blog.currentArticleHeight) * 100;
            return _ret > 100 ? 100 : _ret;
        },
    },
};
</script>

<style scoped></style>
