<template>
    <portal selector="body">
        <div ref="modalWrapper" class="">
            <transition name="fade-in-modal">
                <div
                    v-if="hasCarousel ? true : isOpen"
                    v-show="isOpen"
                    class="modal-scroll-container fixed w-full h-full left-0 nicescroll md:overflow-y-auto z-20 lg:z-50 flex items-end lg:items-center top-0 justify-center"
                >
                    <div
                        class="fixed top-0 left-0 w-full h-full bg-black opacity-0 z-60 overlay"
                        :class="{ 'pointer-events-none': arePointerEventsDisabledOnBgOverlay }"
                        @click="closeModal"
                    ></div>
                    <div
                        class="modal-content nicescroll h-auto md:h-auto overflow-y-auto md:overflow-y-visible grid gap-5 z-50 relative flex items-end lg:items-center bg-white lg:bg-transparent rounded-t-xl lg:rounded-none"
                    >
                        <slot></slot>
                        <button class="close-btn" @click="closeModal"></button>
                    </div>
                </div>
            </transition>
        </div>
    </portal>
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from '@/static/scripts/body-scroll-lock.min';

export default {
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
        hasCarousel: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCentered: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            keyPressHandler: this.handleKeyPress,
            arePointerEventsDisabledOnBgOverlay: true,
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                setTimeout(() => {
                    this.arePointerEventsDisabledOnBgOverlay = false;
                    disableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
                    document.body.style.overflow = 'hidden';
                }, 250);
            } else {
                document.body.style.overflow = 'visible';
                enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', this.keyPressHandler);
    },
    methods: {
        closeModal() {
            enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
            document.body.style.overflow = 'visible';
            this.arePointerEventsDisabledOnBgOverlay = true;
            this.close();
        },
        handleKeyPress(e) {
            if (this.isOpen && e.key === 'Escape') {
                enableBodyScroll(this.$refs.modalWrapper.querySelector('.modal-content'));
                document.body.style.overflow = 'visible';
                this.arePointerEventsDisabledOnBgOverlay = true;
                this.close();
            }
        },
    },
};
</script>

<style scoped>
.modal-content {
    max-height: max-content;
    @media (max-width: 768px) {
        max-height: 100dvh;
    }
}
.overlay {
    @keyframes fadeInOverlay {
        from {
            @apply opacity-0;
        }
        to {
            @apply opacity-60;
        }
    }
    animation: fadeInOverlay 0.25s forwards 0.15s;
}
.close-btn {
    @apply w-5 h-5 absolute right-4 top-4 bg-center bg-no-repeat bg-contain transition hover:scale-[1.2] bg-white p-4 rounded-full;
    background-image: url('~/assets/images/icons/close-icon-black.svg');
    background-size: 50%;
}
</style>
