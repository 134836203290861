<template>
    <div v-if="alt">
        <div v-if="white">
            <svg
                :width="lg ? '414' : '207'"
                :height="lg ? '50' : '25'"
                viewBox="0 0 207 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-auto"
            >
                <path d="M19.4437 24.3967H0V1.642H19.1854L11.6396 11.8491L19.4437 24.3967Z" fill="#001426" />
                <path d="M32.6096 0.663788L44.6719 24.397H20.5474L32.6096 0.663788Z" fill="#227AFF" />
                <path
                    d="M65.2817 24.3967H46.7852V1.642H54.4562C58.5813 1.642 64.8903 1.642 64.8903 8.43633C64.8903 11.9509 63.1996 14.2209 59.458 15.1993L65.2739 24.3967H65.2817Z"
                    fill="#001426"
                />
                <path
                    d="M86.9329 9.63394H81.3049V24.3967H73.1799V9.63394H67.0039V1.642H86.9329V9.64177V9.63394Z"
                    fill="#001426"
                />
                <path d="M96.7566 0.663788L108.819 24.397H84.6943L96.7566 0.663788Z" fill="#FF3B20" />
                <path
                    d="M119.057 17.5085V24.4046H110.932V1.642H119.057C121.758 1.642 129.006 1.97076 129.006 9.67308C129.006 14.7767 125.758 17.3128 119.057 17.5085Z"
                    fill="#001426"
                />
                <path
                    d="M130.861 12.9841C130.861 6.22111 135.84 1.25061 142.665 1.25061C149.491 1.25061 154.399 6.22111 154.399 12.9841C154.399 19.7472 149.428 24.7881 142.697 24.7881C135.965 24.7881 130.861 19.8176 130.861 12.9841Z"
                    fill="#FFD600"
                />
                <path d="M173.483 16.397V24.3967H157.162V1.642H165.287V16.4048H173.483V16.397Z" fill="#001426" />
                <path d="M176.308 1.642H184.433V24.3967H176.308V1.642Z" fill="#001426" />
                <path
                    d="M187.431 16.3971C191.721 16.3971 193.216 15.2934 195.627 9.24273C197.709 4.00609 200.535 1.63434 205.896 1.63434H207V9.6028H206.969C204.433 9.6028 203.525 11.9119 202.186 15.5831C199.979 21.6651 195.908 24.3969 189.537 24.3969H187.423V16.3971H187.431Z"
                    fill="#001426"
                />
            </svg>
        </div>
        <div v-else>
            <svg
                :width="lg ? 414 : 207"
                :height="lg ? 50 : 25"
                viewBox="0 0 207 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-full"
            >
                <path d="M19.4437 24.3967H0V1.642H19.1854L11.6396 11.8491L19.4437 24.3967Z" fill="#001426" />
                <path d="M32.6096 0.663788L44.6719 24.397H20.5474L32.6096 0.663788Z" fill="#227AFF" />
                <path
                    d="M65.2817 24.3967H46.7852V1.642H54.4562C58.5813 1.642 64.8903 1.642 64.8903 8.43633C64.8903 11.9509 63.1996 14.2209 59.458 15.1993L65.2739 24.3967H65.2817Z"
                    fill="#001426"
                />
                <path
                    d="M86.9329 9.63394H81.3049V24.3967H73.1799V9.63394H67.0039V1.642H86.9329V9.64177V9.63394Z"
                    fill="#001426"
                />
                <path d="M96.7566 0.663788L108.819 24.397H84.6943L96.7566 0.663788Z" fill="#FF3B20" />
                <path
                    d="M119.057 17.5085V24.4046H110.932V1.642H119.057C121.758 1.642 129.006 1.97076 129.006 9.67308C129.006 14.7767 125.758 17.3128 119.057 17.5085Z"
                    fill="#001426"
                />
                <path
                    d="M130.861 12.9841C130.861 6.22111 135.84 1.25061 142.665 1.25061C149.491 1.25061 154.399 6.22111 154.399 12.9841C154.399 19.7472 149.428 24.7881 142.697 24.7881C135.965 24.7881 130.861 19.8176 130.861 12.9841Z"
                    fill="#FFD600"
                />
                <path d="M173.483 16.397V24.3967H157.162V1.642H165.287V16.4048H173.483V16.397Z" fill="#001426" />
                <path d="M176.308 1.642H184.433V24.3967H176.308V1.642Z" fill="#001426" />
                <path
                    d="M187.431 16.3971C191.721 16.3971 193.216 15.2934 195.627 9.24273C197.709 4.00609 200.535 1.63434 205.896 1.63434H207V9.6028H206.969C204.433 9.6028 203.525 11.9119 202.186 15.5831C199.979 21.6651 195.908 24.3969 189.537 24.3969H187.423V16.3971H187.431Z"
                    fill="#001426"
                />
            </svg>
        </div>
    </div>
    <div v-else>
        <div v-if="white">
            <svg width="207" height="25" viewBox="0 0 207 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4437 24.3967H0V1.642H19.1854L11.6396 11.8491L19.4437 24.3967Z" fill="#001426" />
                <path d="M32.6096 0.663788L44.6719 24.397H20.5474L32.6096 0.663788Z" fill="#227AFF" />
                <path
                    d="M65.2817 24.3967H46.7852V1.642H54.4562C58.5813 1.642 64.8903 1.642 64.8903 8.43633C64.8903 11.9509 63.1996 14.2209 59.458 15.1993L65.2739 24.3967H65.2817Z"
                    fill="#001426"
                />
                <path
                    d="M86.9329 9.63394H81.3049V24.3967H73.1799V9.63394H67.0039V1.642H86.9329V9.64177V9.63394Z"
                    fill="#001426"
                />
                <path d="M96.7566 0.663788L108.819 24.397H84.6943L96.7566 0.663788Z" fill="#FF3B20" />
                <path
                    d="M119.057 17.5085V24.4046H110.932V1.642H119.057C121.758 1.642 129.006 1.97076 129.006 9.67308C129.006 14.7767 125.758 17.3128 119.057 17.5085Z"
                    fill="#001426"
                />
                <path
                    d="M130.861 12.9841C130.861 6.22111 135.84 1.25061 142.665 1.25061C149.491 1.25061 154.399 6.22111 154.399 12.9841C154.399 19.7472 149.428 24.7881 142.697 24.7881C135.965 24.7881 130.861 19.8176 130.861 12.9841Z"
                    fill="#FFD600"
                />
                <path d="M173.483 16.397V24.3967H157.162V1.642H165.287V16.4048H173.483V16.397Z" fill="#001426" />
                <path d="M176.308 1.642H184.433V24.3967H176.308V1.642Z" fill="#001426" />
                <path
                    d="M187.431 16.3971C191.721 16.3971 193.216 15.2934 195.627 9.24273C197.709 4.00609 200.535 1.63434 205.896 1.63434H207V9.6028H206.969C204.433 9.6028 203.525 11.9119 202.186 15.5831C199.979 21.6651 195.908 24.3969 189.537 24.3969H187.423V16.3971H187.431Z"
                    fill="#001426"
                />
            </svg>
        </div>
        <div v-else>
            <svg
                :width="lg ? 310.5 : 207"
                :height="lg ? 50 : 37.5"
                viewBox="0 0 207 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M19.4437 24.3967H0V1.642H19.1854L11.6396 11.8491L19.4437 24.3967Z" fill="#001426" />
                <path d="M32.6096 0.663788L44.6719 24.397H20.5474L32.6096 0.663788Z" fill="#227AFF" />
                <path
                    d="M65.2817 24.3967H46.7852V1.642H54.4562C58.5813 1.642 64.8903 1.642 64.8903 8.43633C64.8903 11.9509 63.1996 14.2209 59.458 15.1993L65.2739 24.3967H65.2817Z"
                    fill="#001426"
                />
                <path
                    d="M86.9329 9.63394H81.3049V24.3967H73.1799V9.63394H67.0039V1.642H86.9329V9.64177V9.63394Z"
                    fill="#001426"
                />
                <path d="M96.7566 0.663788L108.819 24.397H84.6943L96.7566 0.663788Z" fill="#FF3B20" />
                <path
                    d="M119.057 17.5085V24.4046H110.932V1.642H119.057C121.758 1.642 129.006 1.97076 129.006 9.67308C129.006 14.7767 125.758 17.3128 119.057 17.5085Z"
                    fill="#001426"
                />
                <path
                    d="M130.861 12.9841C130.861 6.22111 135.84 1.25061 142.665 1.25061C149.491 1.25061 154.399 6.22111 154.399 12.9841C154.399 19.7472 149.428 24.7881 142.697 24.7881C135.965 24.7881 130.861 19.8176 130.861 12.9841Z"
                    fill="#FFD600"
                />
                <path d="M173.483 16.397V24.3967H157.162V1.642H165.287V16.4048H173.483V16.397Z" fill="#001426" />
                <path d="M176.308 1.642H184.433V24.3967H176.308V1.642Z" fill="#001426" />
                <path
                    d="M187.431 16.3971C191.721 16.3971 193.216 15.2934 195.627 9.24273C197.709 4.00609 200.535 1.63434 205.896 1.63434H207V9.6028H206.969C204.433 9.6028 203.525 11.9119 202.186 15.5831C199.979 21.6651 195.908 24.3969 189.537 24.3969H187.423V16.3971H187.431Z"
                    fill="#001426"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        white: {
            type: Boolean,
            required: false,
            default: false,
        },
        alt: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style>
footer {
    svg {
        width: auto;
    }
}
header {
    svg {
        width: 100%;
    }
}
</style>
