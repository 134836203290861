<template>
    <BaseModal :close="close" :is-open="isOpen">
        <div
            class="bg-white lg:rounded-md w-screen md:w-[78vw] xl:w-[45vw] lg:min-h-[65vh] lg:h-auto px-6 pt-9 pb-11 lg:py-12 lg:px-12 lg:pb-16 h-full overflow-y-scroll nicescroll-medium"
        >
            <div class="flex flex-col justify-end lg:justify-center text-center">
                <h3 class="font-Inter-Bold text-[1.5rem] lg:text-2xl mb-3">Regisztráció</h3>
                <h4
                    class="font-Inter-Regular text-xs md:text-sm leading-4 md:leading-5 lg:w-10/12 lg:mx-auto mb-6 lg:mb-9"
                >
                    Hozza létre fiókját az e-mail címe megadásával! Így hozzáférése lesz a rendeléseinek történetéhez, a
                    tájékoztató üzenetekhez.
                </h4>
                <div>
                    <RegistrationForm />
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from '~/components/UI/BaseModal.vue';
import RegistrationForm from '~/components/Forms/RegistrationForm.vue';
export default {
    components: {
        RegistrationForm,
        BaseModal,
    },
    props: {
        close: {
            type: Function,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
};
</script>
