<template>
    <transition :name="$device.isMobile ? 'fade-in-top' : 'fade-in-left'">
        <div
            v-if="visibility"
            class="flex items-center p-4 md:p-5 rounded-none sm:rounded-xl bg-black bg-opacity-90 z-10 min-w-[22rem] max-w-full md:max-w-[22rem]"
        >
            <div class="relative flex items-center w-full sm:w-auto">
                <img
                    class="w-8 md:w-10 h-8 md:h-10 object-contain mr-5"
                    :src="require(`~/assets/images/emojis/${emoji}`)"
                    :alt="title"
                />
                <div class="flex flex-col text-white">
                    <span class="block font-Inter-Bold mb-1 text-sm md:text-base">
                        {{ title }}
                    </span>
                    <span class="block text-xs font-Inter-Regular opacity-80 leading-4">
                        {{ subtitle }}
                    </span>
                </div>
                <div
                    class="absolute w-4 md:w-5 h-4 md:h-5 flex items-center justify-center rounded-full right-0 md:-right-2 top-0 md:-top-2 block bg-white scale-100 hover:scale-110 transition-all cursor-pointer"
                    @click="close"
                >
                    <i class="ri-close-line text-black text-sm md:text-base"></i>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ToastMessage',
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        emoji: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visibility: false,
        };
    },
    mounted() {
        this.visibility = true;
        setTimeout(() => {
            this.visibility = false;
        }, 3000);
    },
    methods: {
        close() {
            this.visibility = false;
        },
    },
};
</script>

<style scoped>
.toast-message {
    @keyframes slideFromBottom {
        from {
            opacity: 0;
            transform: translateY(-1rem);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @apply opacity-0 translate-y-0;
    animation: slideFromBottom 0.5s forwards;
}
</style>
